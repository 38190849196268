<script lang="ts">
	export let index = 0;
	const tips = [
		"Puedes cambiar el modo de juego pinchando en el título (WordSevillista).",
		"El Modo Difícil es un modo de juego específico. Activarlos en un modo de juego no lo cambiará en los demás.",
		"La palabra a adivinar puede ser un jugador, presidente o miembro del club. También puede ser emblemas, estadios o ciudades relacionadas con el Sevilla.",
		"El Modo Difícil puede ser habilitado durante el juego si no has infringido las reglas de este modo.",
		"Pulse dos veces o haga clic con el botón derecho en la siguiente fila para ver cuántas palabras posibles se pueden reproducir allí, si se utiliza toda la información anterior.",
		"Como las palabras se eligen de la lista al azar, es posible que se repita la misma palabra.",
		"Cuando veas el botón de actualización en la esquina superior izquierda significa que una nueva palabra está lista.",
		"Todo el mundo tiene el mismo wordle al mismo tiempo. Tu palabra #73 es la misma que la de los demás.",
		"Hay más conjeturas válidas que palabras posibles, es decir, no todas las palabras de 5 letras pueden ser seleccionadas como respuesta por el juego.",
		"Las partidas históricas no cuentan para tus estadísticas. Las partidas históricas son cuando sigues un enlace a un número de partida específico.",
	];
	export const length = tips.length;
</script>

<div class="outer">
	<div class="number">Consejo {index + 1}/{tips.length}</div>
	<div class="tip">{tips[index]}</div>
	<svg
		class="left"
		on:click={() => (index = (index - 1 + tips.length) % tips.length)}
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 100 100"
	>
		<path d="M75,0L25,50L75,100z" />
	</svg>
	<svg
		on:click={() => (index = (index + 1) % tips.length)}
		class="right"
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 100 100"
	>
		<path d="M25,0L75,50L25,100z" />
	</svg>
</div>

<style lang="scss">
	.outer {
		margin: 15px auto;
		padding: 10px 20px;
		max-width: calc(0.6 * var(--game-width));
		border: solid 1px var(--border-secondary);
		background: var(--bg-secondary);
		border-radius: 4px;
		position: relative;
	}
	.number {
		text-align: center;
		font-weight: bold;
		font-size: 1.2em;
		margin-bottom: 10px;
	}
	.left,
	.right {
		cursor: pointer;
		position: absolute;
		border-radius: 4px;
		background: var(--fg-primary);
		fill: var(--bg-primary);
		height: 45px;
		padding: 10px 0;
		top: 50%;
	}
	.left {
		left: 0;
		transform: translate(-50%, -50%);
	}
	.right {
		right: 0;
		transform: translate(50%, -50%);
	}
	.tip {
		text-align: center;
		min-height: 70px;
	}
</style>
