<script lang="ts">
	import { COLS, ROWS } from "../../utils";

	import { Tile } from "../board";
	export let visible: boolean;
</script>

<h3>Cómo jugar</h3>
<div>Adivina la palabra relacionada con el <strong>Sevilla FC</strong> en {ROWS} intentos.</div>
<div>Puede ser el nombre de un jugador, de un entrenador, de algún detalle, algo histórico, etc...</div>
<div>
	Después de cada intento el color de las letras cambia para mostrar qué tan cerca estás de acertar
	la palabra.
</div>
<div class:complete={visible} class="examples">
	<div><strong>Ejemplos</strong></div>
	<div class="row">
		<Tile value="c" state="🟩" />
		<Tile value="a" state="🔳" />
		<Tile value="r" state="🔳" />
		<Tile value="t" state="🔳" />
		<Tile value="a" state="🔳" />
		<Tile value="s" state="🔳" />
	</div>
	<div>La letra <strong>C</strong> está en la palabra y en la posición correcta.</div>
	<div class="row">
		<Tile value="f" state="🔳" />
		<Tile value="u" state="🔳" />
		<Tile value="t" state="🟨" />
		<Tile value="b" state="🔳" />
		<Tile value="o" state="🔳" />
		<Tile value="l" state="🔳" />
	</div>
	<div>La letra <strong>T</strong> está en la palabra pero en la posición incorrecta.</div>
	<div class="row">
		<Tile value="g" state="🔳" />
		<Tile value="r" state="🔳" />
		<Tile value="a" state="🔳" />
		<Tile value="d" state="⬛" />
		<Tile value="a" state="🔳" />
		<Tile value="s" state="🔳" />
	</div>
	<div>La letra <strong>D</strong> no está en la palabra. Puede haber letras repetidas.</div>
</div>
<div>
	Este juego es una recreación del original <a
		href="https://www.nytimes.com/games/wordle/"
		target="_blank">Wordle</a
	>
	creado por Josh Wardle con modos adicionales y funcionalidades, permitiendo que puedas jugar infinitos wordles. Cambia
	al modo infinito para jugar un número de veces ilimitadas.
	<br /><br />
	Abre el menú de ajustes para ver algunas de las funcionalidades adicionales.
	<br />
	Escrito con Svelte, en Typescript por
	<a href="https://github.com/MikhaD" target="_blank">MikhaD</a> y modificado por <a href="https://samuguerra.com/" target="_blank">Samu Guerra</a>.
</div>

<style lang="scss">
	div {
		margin: 14px 0;
	}
	.examples {
		border-top: 1px solid var(--border-primary);
		border-bottom: 1px solid var(--border-primary);
		:global(.row > *) {
			height: 100%;
			aspect-ratio: 1;
		}
		&:not(.complete) :global(.row .back) {
			transition-delay: 0.3s;
		}
	}
	.row {
		height: 40px;
		display: flex;
		gap: 4px;
	}
</style>
